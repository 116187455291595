<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="agree-contanier">
    <div class="agree-box">
      <h2>电波谷子隐私协议</h2>
      概述
      <br/>本政策将帮助您了解以下内容：
      <br/>一、我们如何收集和使用您的个人信息
      <br/>二、我们如何使用Cookie、Beacon、Proxy等技术
      <br/>三、我们如何存储您的个人信息
      <br/>四、我们如何保护您的个人信息
      <br/>五、我们如何对外提供个人信息
      <br/>六、您如何实现您的个人信息权利
      <br/>七、对第三方责任的声明
      <br/>八、我们如何保护未成年人的信息
      <br/>九、本政策的适用和更新
      <br/>十、本政策中关键词说明 
      <br/>电波谷子是由电波谷子（扬州）科技有限公司（通过下述途径向用户（以下简称“您”）提供的各项产品/服务：包括但不限于App等客户端以及开放平台账号或小程序等。我们深知个人信息对用户（“您”）的重要性。我们非常注重保护您的个人信息及隐私，我们希望通过本隐私权政策（以下简称：“本政策”）向您介绍在使用我们的服务时，我们将如何处理您的个人信息，以及我们为您提供的访问、更正、删除和保护这些信息的方式。本政策与您使用我们的产品/服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。对本政策中与您的权益存在重大关系的条款和个人信息，我们将在正文中以粗体字和/或下划线进行标注以提示您注意。
      <br/>其中要点如下：

      <br/>1.为了便于您了解在使用我们的服务时，我们需要收集的信息类型与用途，我们将结合具体服务向您逐一说明。

      <br/>2.为了向您提供服务所需，我们会按照合法、正当、必要、诚信的原则收集您的信息。

      <br/>3.如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。

      <br/>4.如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性；如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。

      <br/>5.您可以通过本政策介绍的方式访问和管理您的信息、设置隐私功能、或进行投诉举报。
      
      

      <br/>一、我们如何收集和使用您的个人信息
      
      

      <br/>1.1根据相关法律法规及国家标准，我们可能在下列情形下依法处理您的个人信息：

      <br/>（1）取得您的同意；

      <br/>（2）根据您的要求订立和履行合同所必需的；

      <br/>（3）履行法定义务所必需的，如反洗钱、反恐怖融资、实名管理等相关法律法规要求的必要信息；

      <br/>（4）与国家安全、国防安全直接相关的；

      <br/>（5）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；

      <br/>（6）与犯罪侦查、起诉、审判和判决执行等直接相关的；

      <br/>（7）所收集的个人信息是您自行公开的；

      <br/>（8）从合法公开披露的信息中收集的个人信息，例如：合法的新闻报道、政府信息公开等渠道；

      <br/>（9）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；

      <br/>（10）法律法规规定的其他情形。

      <br/>需要注意的是，当您的个人信息处理属于前述第（2）至第（10）项情形时，我们可能会依据所适用法律法规的要求而无需再征得您的同意。

      <br/>1.2帮助您成为我们的用户并提供账号服务

      <br/>1.2.1账号的注册、登录

      <br/>为遵守法律法规的要求，您在注册并登录电波谷子账号后才能使用评论、分享及其他用户服务，在未注册或登录账号的情况下您仅可以进行浏览、搜索。

      <br/>账号的注册：当您注册账号时，您需要提供手机号码以创建账号。手机号码是履行国家法律法规关于网络实名制要求的必要信息，如您未提供手机号码用于注册、登录，我们可能无法为您提供评论、分享等功能。同时为完善您的账号信息，您可以提供非注册必须信息（如：头像、账号名、昵称、简介）来完善您的网络身份标识信息。当您使用编辑头像或上传图片功能时，我们在获得您的授权同意后将访问您的相册（存储）权限。

      <br/>1.2.2账号实名认证

      <br/>在您使用实名认证的功能或购买数字藏品等相关服务时，根据相关法律法规，您需要向我们和提供认证服务的第三方认证机构提供您的真实姓名、身份证号码、手机号码、面部特征（如需）等身份证明信息以完成验证。特别且必要情况下基于安全考虑，为了确保我们为您本人提供服务，我们可能会根据您提供的信息进行校验，将通过人脸识别的方式验证您的身份。当您使用实名认证功能时，我们在获得您的授权同意后将访问您的摄像头权限。身份证号码、电话号码、人脸信息属于您的敏感个人信息，您可以拒绝提供，此时您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。

      <br/>1.2.3账号找回

      <br/>当您使用账号找回功能时，为保障您账号的安全所必需，在找回账号时我们可能需要您提供相关信息以进行验证，必要时可能通过实名验证、人脸识别等方式验证您的身份。

      <br/>1.3帮助您购买或转赠数字藏品。

      <br/>1.3.1在您购买或转赠数字藏品时，根据区块链信息服务以及反洗钱相关要求，您需要完成账号实名认证。如您的账号未完成实名认证，您可能无法使用购买或转赠数字藏品的功能，但不影响您继续使用我们的其他服务。

      <br/>1.3.2在您购买或转赠数字藏品时，我们会通过系统为您生成您的购买或转赠记录并上链存储（如果适用）。基于区块链技术的特性，数字藏品具有加密和不可篡改的特点，您购买或转赠相关数字藏品后，您的购买、转赠信息（交易时间、交易订单号）将进行加密处理并记录在区块链中，无法进行篡改或删除。您可以通过「我的-订单」查看和管理您的交易信息。

      <br/>1.4互动交流

      <br/>在您分享或接收被分享的信息、参加活动时（如炫耀或转赠数字藏品），我们需要访问您的剪贴板、相册，以便读取其中包含的口令、分享码、二维码、链接并实现跳转、分享等功能或服务。我们仅会对剪贴板、相册中的信息在您的设备本地端进行判断，不会将其中的信息回传至服务器。如您未开启相册权限，将无法使用上述功能，但您仍可正常使用我们的其他功能。

      <br/>1.5消息通知

      <br/>为向您提供服务，我们可能会使用您在使用产品及/或服务的过程中提供的您的联系方式（例如：联系电话），以便向您发送消息告知、身份验证、安全验证、用户使用体验调研、争议处理等各类通知；此外，我们也可能会通过向前述过程中收集的联系方式以短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动等商业性信息；如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。

      <br/>1.6用户服务及服务质量的改进

      <br/>为了提升您的服务体验并改进我们的服务质量，或者为您推荐更优质或更适合的服务，我们会收集您使用我们服务的操作记录、您与客户服务团队联系时提供的信息及您参与问卷调查时向我们发送的信息。如您不提供前述信息，不影响您使用我们提供的其他服务。

      <br/>1.7安全管理

      <br/>为了提高您使用我们产品/服务过程中的安全性，防止您的账号、资金、个人信息被不法分子获取，预防病毒、木马程序或其他恶意程序、网站，我们需要记录您使用的服务类别、方式及相关操作信息，例如：设备品牌、设备型号、设备名称、IP地址、网络设备硬件地址（MAC地址）、设备软件版本信息、设备识别信息（如IMEI、AndroidID、IDFA、OAID等）、所在位置、网络使用习惯以及其他与服务相关的日志信息。上述信息是我们用于维护产品/服务安全稳定运行的必要信息，如您不能提供前述信息，可能无法完成您所使用产品/服务的风控验证。

      <br/>1.8基于系统权限为您提供的其他附加服务

      <br/>1.8.1为向您提供更便捷、更优质、个性化的产品/服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限的方式以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用我们提供的其他服务，但您可能无法获得这些附加服务给您带来的用户体验。您可在您的设备“设置”或我们客户端“我的-设置-通用设置-系统权限管理”中逐项查看上述权限的状态，并可自行决定这些权限的开启或关闭。

      <br/>1.8.2请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。

      <br/>1.9其他

      <br/>请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
      
      
      
      

      <br/>二、我们如何使用Cookie、Beacon、Proxy等技术
      
      

      <br/>2.1为使您获得更轻松的访问体验，您访问电波谷子网站或使用提供的产品/服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账号安全状态。这些数据文件可能是Cookie，FlashCookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝的Cookie。多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的FlashCookie及类似数据。但这一举动在某些情况下可能会影响您安全访问网站和使用电波谷子提供的服务。

      <br/>2.2我们网站上还可能包含一些电子图像（以下简称“网络Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您浏览网页活动的信息（您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定）。

      <br/>2.3如您通过我们的网站或APP，使用了由第三方而非电波谷子提供的服务时，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术（以下简称“Proxy技术”）。使用Proxy技术，可以帮助您识别到我们已知的高风险站点，减少由此引起的钓鱼、账号泄露等风险，同时更有利于保障您和第三方的共同权益，阻止不法分子篡改您和您希望访问的第三方之间正常服务内容，包括：不安全路由器、非法基站等引起的广告注入、非法内容篡改。在此过程中，我们也可能会获得和保存您设备的IP地址、硬件ID以及您访问的页面位置。
      
      
      
      

      <br/>三、我们如何存储您的个人信息
      
      

      <br/>3.1我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格遵照法律法规和相关监管部门的规定执行，向您说明个人信息出境的目的以及涉及的个人信息类型，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。

      <br/>3.2我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。如您注销账号或超出必要的保存期限后，我们会根据适用法律法规的要求对所持有的您的个人信息进行删除或匿名化处理。

      <br/>3.3如果我们发生停止产品/服务等情形，我们将会至少提前三十日向您发出通知，并在停止产品/服务后根据适用法律法规的要求对所持有的您的个人信息进行删除或匿名化处理。
      
      
      
      

      <br/>四、我们如何保护您的个人信息
      
      

      <br/>4.1为了保障您的信息安全，我们在收集您的信息后，将采取各种合理必要的措施保护您的信息。例如，在技术开发环境当中，我们仅使用经过去标识化处理的信息进行统计分析；对外提供研究报告时，我们将对报告中所包含的信息进行去标识化处理。我们会将去标识化后的信息与可用于恢复识别个人的信息分开存储，确保在针对去标识化信息的后续处理中不重新识别个人。

      <br/>4.2我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问。传输和存储敏感个人信息（含个人生物识别信息）时，我们将采用加密、权限控制、去标识化/匿名化等安全措施。

      <br/>4.3我们有专门的个人信息保护责任人，针对个人信息处理开展个人信息安全影响评估。同时，我们建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权的原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织全体工作人员参加安全考试。另外，我们的相应网络/系统通过了国家网络安全等级保护的测评。我们每年还会聘请外部独立第三方对我们的信息安全管理体系进行评估。

      <br/>4.4我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。

      <br/>4.5请您务必妥善保管好您的电波谷子登录名及其他身份要素。您在使用我们提供的产品/服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现您的登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。

      <br/>4.6在您终止使用某项服务后，我们会停止对您个人信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的您的个人信息进行删除或匿名化处理。
      
      
      
      

      <br/>五、我们如何对外提供个人信息
      
      

      <br/>5.1委托处理

      <br/>为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司或其他第三方代表我们来处理您的信息。我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务并采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托方不再保存个人信息。我们承诺对此负责。

      <br/>5.2共享

      <br/>5.2.1业务共享

      <br/>我们承诺对您的信息进行保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括关联公司、合作机构以及其他合作伙伴。在将信息提供给第三方前，我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，尽力要求第三方对您的信息采取保护措施。

      <br/>（1）向您提供服务所必需的共享。某些产品或服务可能由第三方提供或由相关服务提供方与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务，例如：区块链技术服务的提供方、数字藏品的发行方。

      <br/>（2）如您选择参与我们和第三方联合开展的运营活动（如抽奖、竞赛或类似推广活动），我们可能与其共享活动过程中产生的、为完成活动所必要的信息（包括但不限于您的专属邀请码、您的数字藏品的持有数量和转赠数量等），以便第三方能及时向您发放奖品或为您提供服务，相关服务提供方会依据法律法规或国家标准的要求，在活动规则页面或通过其他途径向您明确告知需要向第三方提供何种个人信息。

      <br/>（3）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。

      <br/>5.2.2其他用户查询

      <br/>为了确保数字藏品转赠对象的准确性，避免因输错账号导致的损失，他人可通过搜索您的手机号搜索到您，并看到您账号对应的昵称、头像，以便向您转赠数字藏品。

      <br/>5.2.3投诉处理

      <br/>当您投诉他人或被他人投诉时，为了保护您及他人的合法权益，我们可能会将您的姓名、有效证件号码、联系方式、投诉相关内容等提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。

      <br/>5.3转让

      <br/>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：

      <br/>（1）事先获得您的明确同意；

      <br/>（2）根据法律法规或强制性的行政或司法要求；

      <br/>（3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。

      <br/>5.4公开披露

      <br/>5.4.1除下述情况外，原则上我们不会将您的信息进行公开披露。如确需公开披露的，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。

      <br/>（1）在公布中奖活动名单时会根据具体活动参与规则脱敏展示中奖者手机号或账号登录名；

      <br/>（2）对违规账号、欺诈行为进行处罚公告时，我们会公开相关账号信息；

      <br/>（3）法律、行政法规规定的其他情形。

      <br/>5.4.2特别提示您注意，您在使用我们产品/服务时自愿发布或公开分享的信息（如分享您拥有的数字藏品），可能会涉及您或他人的个人信息甚至敏感个人信息（如您的账号昵称、头像、交易信息）。请您在使用我们的服务时谨慎地考虑，是否要发布甚至公开分享相关信息。
      
      
      
      

      <br/>六、您如何实现您的个人信息权利

      <br/>在您使用我们的产品/服务期间，为了您可以更加便捷地访问和管理您的信息，同时保障您的个人信息权利，我们在客户端中为您提供了相应的操作设置，您可以参考下面的指引进行操作。

      <br/>6.1查阅、更正和补充

      <br/>您有权访问、更正、补充您的个人信息，法律法规规定的例外情况除外。您在电波谷子APP中登录账号后，可以通过以下方式管理您的信息：

      <br/>（1）账号信息：通过访问“我的-设置-账号与安全”访问、更正、补充您账号中的个人资料及账号信息、账号绑定信息及实名认证状态。

      <br/>（2）订单信息：通过访问“我的-订单”查询您账号中购买的数字藏品订单信息。

      <br/>（3）转赠/接收记录：通过访问：我的-记录“查询您订单中的数字藏品转赠、接收记录。

      <br/>如您发现相应信息有误的，您可以根据页面提示自助修改您的信息，您可以通过“我的-设置-我的客服”联系在线客服要求更正。出于安全性和身份识别的考虑或根据法律法规及监管规定，您需通过验证方可修改您已认证的姓名或已绑定的手机号码。

      <br/>6.2删除

      <br/>您可以通过6.1中列明的路径删除您的部分信息，或申请注销账号删除您的全部信息。如您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可以通过与客服联系向我们提出删除个人信息的请求。

      <br/>6.3管理您的授权范围

      <br/>您可以通过手机设备权限设置及电波谷子APP“我的-设置-通用-系统权限管理”来管理APP使用的系统权限授权。对于您无法直接通过上述方式设置的授权，您可以通过我们的客服联系进行修改。但请注意，对于部分类型的个人信息，例如实现基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。当您撤回授权后，我们将不再处理相应的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。

      <br/>6.4注销账号

      <br/>您可通过如下路径申请注销账号：登录账号，通过访问“我的-设置-账号与安全-账号注销”即可进入账号注销申请流程。

      <br/>当您符合约定的账号注销条件并注销某项服务相关账号后，我们将按照法律要求删除您该账号内的所有个人信息，但如有相关个人信息按照法律法规规定的保存期限未届满或者其他依法不适宜删除的情形，我们将停止除存储和采取必要的安全保护措施之外的处理。

      <br/>6.5尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：

      <br/>（1）与国家安全、国防安全直接相关的；

      <br/>（2）与公共安全、公共卫生、重大公共利益直接相关的；

      <br/>（3）与犯罪侦查、起诉、审判和执行判决等直接相关的；

      <br/>（4）有充分证据表明您存在主观恶意或滥用权利的；

      <br/>（5）响应您的请求将导致其他个人、组织的合法权益受到严重损害的；

      <br/>（6）涉及商业秘密的。

      <br/>6.6当我们与第三方合作向您提供服务时，第三方将按照其发布的隐私权政策或与您签署的其他条款

      <br/>（如有）告知您个人信息处理者的相关信息，并按照适用的要求严格保护您的信息、告知您行使个人信息权利的方式。请您仔细阅读相关政策、条款；如有疑问，可以根据您所使用的服务咨询该第三方机构。6.7如您对本政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，您可以通过以下渠道联系我们：

      <br/>（1）在线客服：“我的-设置-我的客服”

      <br/>（2）电子邮箱：bussiness@dianboguzi,com
      <br/>客服部门将会同隐私保护责任部门及时答复您。

      <br/>为保障您的信息安全，我们需要先验证您的身份和凭证资料。我们已经建立客户投诉管理机制，包括跟踪流程。我们将在不超过十五个工作日内作出答复。
      

      <br/>七、对第三方责任的声明
      

      <br/>7.1请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务（例如：数字藏品的发行方）和通过我们接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。

      <br/>7.2我们所提供的部分服务需要由合作伙伴共同为您提供（例如：APP的通知栏消息提醒或者地支付服务），为此，我们会嵌入合作伙伴的软件工具开发包（以下简称“SDK”）或其他类似的应用程序。例如：当您使用不同手机设备时，手机厂商提供的PushSDK（消息提醒服务）需要读取您的设备识别号、联网相关信息，用于推送/下发通知栏消息。

      <br/>八、我们如何保护未成年人的信息

      <br/>8.1我们非常重视对未成年人个人信息的保护。我们期望父母或监护人指导未成年人使用我们的服务。我们将根据国家相关法律法规的规定保护未成年人的信息的保密性及安全性。
      <br/>8.2如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。8.3如您为未成年人的父母或监护人，当您对您所监护的未成年人的信息处理存在疑问时，请通过上文中的联系方式联系我们。
      <br/>8.4特别提示：14周岁以下的儿童及其监护人在为儿童注册账号并使用我们的服务前需征得儿童的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
      <br/>九、本政策的适用和更新
      <br/>9.1为给您提供更好的服务，我们提供的产品/服务将不时变化，本政策也将随之更新；我们也会根据国家法律法规的变化要求，更新本政策的相关条款。更新的政策将于更新公告公布的生效之日起生效。

      <br/>9.2发生下列重大变化情形时，我们会适时对本政策进行更新：

      <br/>（1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；

      <br/>（2）收集、存储、使用个人信息的范围、目的、规则发生变化；

      <br/>（3）对外提供个人信息的对象、范围、目的发生变化；

      <br/>（4）您访问和管理个人信息的方式发生变化；

      <br/>（5）数据安全能力、信息安全风险发生变化；

      <br/>（6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；

      <br/>（7）其他可能对您的个人信息权益产生重大影响的变化。

      <br/>9.3如本政策发生更新，我们将以APP推送通知、弹窗提示、发送邮件/短消息或者在官方网站发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们提供的产品/服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
      

      <br/>十、本政策中关键词说明
      

      <br/>个人信息：是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。

      <br/>敏感个人信息：是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。

      <br/>个人信息的处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。

      <br/>账号信息：指用于识别您身份的账号相关信息要素，包括您的ID、手机号码、密码、短信校验码、头像、昵称、简介、证件号码及生物识别信息。

      <br/>数字藏品：是使用区块链技术进行唯一标识的经数字化的特定作品、艺术品和商品，包括但不限于数字图片、音乐、视频、3D模型等各种形式的数字藏品。数字藏品的具体发行方和权益规则等信息以相关页面的具体展示为准。
      
      <br/>补充政策一：《用户支付隐私政策》
      <br/>为充分保障您的权利，我们特制定了《用户支付隐私政策》，本政策仅适用于您在我们平台
      <br/>商户名称：电波谷子（扬州）科技有限公司（以下简称“我们”或“平台”）使用第三方支付机构（例如支付宝、微信、雅酷支付等）支付服务的用户。

      <br/>本政策公布、生效日期：2024年3月20日。
      
      <br/>我们将尽力采取采用通俗易懂的文字表述向您进行说明和告知，请您仔细阅读并完全理解本政策中的全部内容，并自主进行适当的选择。如果您不同意本政策的内容及经公告更新的内容，我们将无法为您提供完整的服务，同时您也需要立即停止使用第三方支付产品及相关服务。如您开始使用我们的产品或服务，则视为您已经充分、完全地阅读并理解了本政策中的全部内容，并且同意我们按照本政策的约定收集、使用及对外提供您的个人信息，以便您享受优质、便捷的服务，同时更好地保护您的账户及资金安全。

      <br/>1、用户个人信息的收集和使用

      <br/>1.1依据法律法规及监管规定履行反洗钱义务及进行实名管理在用户进行订单支付时如要注册第三方支付机构（如北京雅酷时空信息交换技术有限公司，简称“雅酷支付”，下同）支付账户或使用支付服务，用户需按照《非银行支付机构网络支付业务管理办法》的要求通过平台向第三方支付机构付提供用户的身份基本信息，包括姓名、性别、有效身份证件的种类、号码和有效期限、手机号，以及可能为完成注册、支付验证而提供的面部特征。第三方支付机构会按照监管规定核对有效身份证件并留存前述信息。

      <br/>1.2支付服务为完成订单支付，用户可以选择第三方支付机构提供支付服务。平台需要将用户的订单号与交易金额信息，包括用户的银行卡开户行名称、银行卡号（含CVV码）、银行卡有效期、姓名、身份证号、银行预留手机号与第三方支付机构共享以实现其确认用户的支付指令并完成支付。前述信息中包含用户的个人敏感信息，用户知悉并同意第三方支付机构将前述信息通过外部验证渠道（包括但不限于政府部门、中国银联/网联、商业银行、数据服务公司）对银行卡是否为用户本人进行验证，并通过外部验证渠道、第三方支付机构返回核验结果，完成支付；用户同意上述验证渠道可以为验证发起之故向合法留存用户信息的第三方（包括权威数据源）提交用于验证的信息并收集、使用、储存相应的验证结果。在用户使用二维码支付时，还需向第三方支付机构提供用户的设备类型、设备识别码、IP地址、MAC地址信息用于预防交易和资金风险。为使平台及时获悉并确认用户的支付进度及状态、为用户提供售后服务，用户同意平台可自用户所选择的交易对象、第三方支付机构处收集与支付进度相关信息。

      <br/>1.3实名认证（如有）为完成实名认证，平台需要将用户向平台提供姓名、身份证号、手机号（可选）、银行卡号（可选）共享给第三方支付机构，如用户未能提供上述信息，即未完成实名制验证，将无法使用实名制认证服务。用户知悉并同意雅酷支付将前述信息通过外部验证渠道（包括但不限于政府部门、中国银联/网联、商业银行、数据服务公司）对用户身份进行验证，并通过外部验证渠道、第三方支付机构返回核验结果，完成实名认证；用户同意上述验证渠道可以为验证发起之故向合法留存用户信息的第三方（包括权威数据源）提交用于验证的信息并收集、使用、储存相应的验证结果。
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.agree-contanier{
  width: 100%;
  background-image: linear-gradient(90deg, #b2d6fa 12%, #f8d3f8);
  padding: 5% 10% 20px;
  box-sizing: border-box;
  .agree-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    font-family: "宋体",'宋体-简',"常规体";
    font-size: 16px;
    h2{
      font-size: 30px;
      font-family: "宋体",'宋体-简',"常规体";
      text-align: center;
      font-weight: bold;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 750px) {
  .agree-contanier{
    .agree-box{
      font-size: 13px;
      h2{
        font-size: 24px;
      }
    }
  }

}
</style>